/**
 * @author Connor Manning
 */
import { loadedCache } from "./Cache";

export class EptLoader {
	static async load(file, callback) {

		let response = await fetch(file);
		let json = await response.json();

		let url = file.substr(0, file.lastIndexOf('ept.json'));
		let geometry = new Potree.PointCloudEptGeometry(url, json);

		// MODIFIED TO FIX BUG VISION-736
		for(let i of loadedCache) {
			if(i.url === url) {
				loadedCache.splice(loadedCache.indexOf(i), 1);
			}
		}
		// TODO: loadedCache per player instances and not global and remove this splice blob

		let root = new Potree.PointCloudEptGeometryNode(geometry);

		geometry.root = root;
		geometry.root.load();

		callback(geometry);
	}
};

